@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Inter, system-ui, -apple-system, sans-serif;
    box-sizing: border-box;
}

select {
    -webkit-appearance: none;
}

table * {
    font-size: 13px;
}

table thead th {
    color: #aaa;
    font-weight: 500;
    text-transform: uppercase;
}

table td, table th {
    border-bottom: 1px solid #e6e6e6;
    text-align: left;
    font-weight: 400;
    padding: 8px 0;
}

table td {
    color: #000;
}

table th {
    font-weight: 600;
}

table.indexes-table {
    width: 100%;
}

table.indexes-table th, table.indexes-table td {
    border: 0;
    font-size: 14px;
}

table.indexes-table th {
    color: #FFF;
    text-align: start;
}

table.indexes-table td {
    color: #50CD89;
    text-align: right;
}

#header-title {
    position: relative;
}

#header-title::before {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 1.50rem;
    left: 0;
    height: 1em;
    width: 1em;
    border-top-left-radius: 0.5em;
    box-shadow: 0 -0.5em 0 0 rgb(59 130 246);
}

#header-title::after {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 1.50rem;
    right: 0;
    height: 1em;
    width: 1em;
    border-top-right-radius: 0.5em;
    box-shadow: 0 -0.5em 0 0 rgb(59 130 246);
}

.modal.dark label {
    color: #FFFFFF;
}

label.checkbox {
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height: 25px;
	background: #d6d6d6;
	display: block;
	border-radius: 100px;
	position: relative;
}

label.checkbox:after {
	content: '';
	position: absolute;
	top: 2.5px;
	left: 2.5px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 100%;
	transition: 150ms ease;
}

input:checked + label.checkbox {
    background-color: rgb(59 130 246);
}

input:checked + label.checkbox:after {
	left: calc(100% - 2.5px);
	transform: translateX(-100%);
}

label.checkbox:active:after {
	width: 18px;
}

* {
    scrollbar-width: auto;
    scrollbar-color: rgb(59 130 246) #e3e3e3;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #e3e3e3;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(59 130 246);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

div.react-select-mono {
    grid-area: unset!important;
}